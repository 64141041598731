window.VWFS = window.VWFS || {};
VWFS.ModalManager = function (modalLink) {
    console.debug("INFO ModalManager.js loaded");
    var modalElement = document.getElementById('id-modal-generic')
    var spinnerElement = document.getElementById('id-modal-spinner');
    var modalPlaceholderDiv = document.getElementById('id-disclaimer-placeholder');
    var errorDisclaimerContent = document.querySelector(".js-error-modal");

    var newHREF;

    /**
     * OPEN MODAL
     */
    this.openModal = function (newHref) {
        newHREF = newHref;

        modalElement.setAttribute('aria-hidden', 'false');
        modalPlaceholderDiv.innerHTML = spinnerElement.innerHTML;
        modalPlaceholderDiv.querySelector('.c-spinner--full-page').setAttribute('aria-busy', 'true');

        var url = modalLink.getAttribute("data-modal-ref");
        if (!url) {
            url = modalLink.getAttribute("data-disclaimer-ref");
        }

        // GET THE MODAL
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("load", onModalLoaded);
        xhr.open("GET", url, true);
        xhr.send();

    }


    /**
     * EVENT > ON MODAL LOADED
     */
    var onModalLoaded = function (event) {
        if (event.target.readyState === XMLHttpRequest.DONE) {
            if (event.target.status === 200) {

                // Overwrite the spinner and set the generic modal
                modalPlaceholderDiv.innerHTML = event.target.responseText;

                var modalId = modalElement.querySelector('.c-modal__wrapper').getAttribute('aria-labelledby');

                // modalElement.querySelector('.c-modal__title').id = modalId;
                // set href and target value to forward link
                var forwardButton = modalElement.querySelector(".js-modal-close-forward-btn");
                if (forwardButton !== null) {

                    // READ ATTRIBUTES FROM ORIGINAL LINK
                    var titleAttribute = modalLink.getAttribute('title');
                    var relAttribute = modalLink.getAttribute('rel');
                    var trackingId = modalLink.getAttribute('data-tracking-id');
                    var hrefAttribute
                    if (newHREF) {
                        hrefAttribute = newHREF;
                    } else {
                        hrefAttribute = modalLink.getAttribute('href');
                    }

                    var targetAttribute = modalLink.getAttribute('target');
                    if (!targetAttribute) {
                        targetAttribute = '_self';
                    }

                    // APPLY ATTRIBUTES TO FORWARD BUTTON
                    if( titleAttribute != null) {
                        forwardButton.setAttribute('title', titleAttribute);
                    } else {
                        forwardButton.removeAttribute('title');
                    }
                    if( relAttribute != null) {
                        forwardButton.setAttribute('rel', relAttribute);
                    } else {
                        forwardButton.removeAttribute('rel');
                    }
                    if (trackingId) {
                        forwardButton.setAttribute('data-tracking-id', trackingId);
                    } else {
                        forwardButton.removeAttribute('data-tracking-id');
                    }
                    forwardButton.setAttribute('href', hrefAttribute);
                    forwardButton.setAttribute('target', targetAttribute);

                    // set listener for closing modal on click of button
                    forwardButton.addEventListener("click", onModalClosed);

                }
                // set href and target value to back link
                var backButton = modalElement.querySelector(".js-modal-close-back-btn");
                if (backButton !== null) {
                    // READ ATTRIBUTES FROM ORIGINAL LINK
                    var titleAttribute = modalLink.getAttribute('data-title2');
                    var relAttribute = modalLink.getAttribute('rel2');
                    var trackingId = modalLink.getAttribute('data-tracking-id2');
                    var hrefAttribute = modalLink.getAttribute('data-href2');

                    var targetAttribute = modalLink.getAttribute('data-target2');
                    if (!targetAttribute) {
                        targetAttribute = '_self';
                    }

                    // APPLY ATTRIBUTES TO BACK BUTTON
                    if (titleAttribute != null) {
                        backButton.setAttribute('title', titleAttribute);
                    } else {
                        backButton.removeAttribute('title');
                    }
                    if( relAttribute != null) {
                        backButton.setAttribute('rel', relAttribute);
                    } else {
                        backButton.removeAttribute('rel');
                    }
                    if (trackingId) {
                        backButton.setAttribute('data-tracking-id', trackingId);
                    } else {
                        backButton.removeAttribute('data-tracking-id');
                    }
                    backButton.setAttribute('href', hrefAttribute);
                    backButton.setAttribute('target', targetAttribute);

                    // set listener for closing modal on click of button
                    backButton.addEventListener("click", onModalClosed);

                }
                var closeButton = modalElement.querySelector(".js-modal-close-btn");
                if (closeButton) {
                    closeButton.addEventListener("click", onModalClosed);
                } else {
                    console.debug("Debug: ModalManager.js: closeButton is missing.");
                }
            } else {
                console.debug("Error: ModalManager.js: Request failed: " + event.target.status + " / " + event.target.responseText);
                // Overwrite the spinner and set an error modal
                modalPlaceholderDiv.innerHTML = errorDisclaimerContent.outerHTML;
            }
        }

    }


    /**
     * EVENT > Modal Button Clicked
     */
    var onModalClosed = function (event) {
        VWFSUtils.findAncestor(event.target, 'js-disclaimer').setAttribute('aria-hidden', 'true');
    }

};
